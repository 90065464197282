import Axios from '@/services/Axios';
import { ListItem } from '../common/models/ListItem';

const resource = '/user';

export default {
  /**
   * @param {number} userId
   * @returns {Promise<ListItem[]>}
   */
  async getUserStores(userId) {
    const response = await Axios.get(`${resource}/${userId}/stores`);
    return response.data.map(item => (new ListItem(item)));
  },

  /**
   * @param {number} userId
   * @returns {Promise<string[]>}
   */
  async getUserAttributes(userId) {
    const response = await Axios.get(`${resource}/${userId}/attributes`);
    return response.data.data;
  },
};
