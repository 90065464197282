import Axios from '@/services/Axios';
//import { ErrorResponse } from '../common/models/ErrorResponse';

export default {
  async auth(username, password) {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    //const payload = { username, password };
    let response = null;

    try {
      response = await Axios.post('/auth/family', formData);
    } catch (e) {
      console.log(e);
      console.error(e.response.data.message);
      //response = new ErrorResponse(e.response.data);
      return e.response.data;
    }
    response = response.data;

    return response;
  },
};
