/**
 * @param {number} ms
 * @return {Promise<any>}
 */
export const delay = ms => new Promise(_ => setTimeout(_, ms));

export function redirectToUserSectionByAttributes($router, attributes) {
  const adminViews = attributes.filter(item => !!item && item.startsWith('ADMIN_'));
  let path = null;

  if (adminViews.includes('ADMIN_PRODUCTS')) {
    path = '/dashboard';
  } else if (adminViews.includes('ADMIN_EMPLOYEES')) {
    path = '/employees-home';
  } else if (adminViews.includes('ADMIN_CLIENTS')) {
    path = '/clients-home';
  } else if (adminViews.includes('ADMIN_SALES')) {
    path = '/sales';
  } else if (adminViews.includes('ADMIN_PURCHASE_ORDERS')) {
    path = '/quotations';
  } else if (adminViews.includes('ADMIN_KITS')) {
    path = '/kits';
  } else if (adminViews.includes('ADMIN_CATALOGS')) {
    path = '/tags-home';
  } else if (adminViews.includes('ADMIN_PROMOTIONS')) {
    path = '/specials-discount-home';
  } else if (adminViews.includes('ADMIN_INVENTORY')) {
    path = '/stores-inventory';
  } else if (adminViews.includes('ADMIN_SALES_ORDERS_ADMIN')) {
    path = '/dashboard';
  }

  if (path) {
    $router.push(path);
  }
}
