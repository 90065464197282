<template>
  <ion-page>
    <!-- <ion-header>
      <ion-toolbar>
        <ion-title>CENDI</ion-title>
      </ion-toolbar>
    </ion-header> -->

    <ion-content class="backgroundImage">
      <ion-grid>
        <img style="width: 300px; margin: 50px" :src="require('@/images/logINGRESSYFYblanco.png')" alt=".">
        <ion-row>
          <ion-col size="11" style="margin: 15px;">
            <ion-item class="itemInput">
              <ion-label position="stacked">Usuario</ion-label>
              <ion-input type="text" v-model="username"></ion-input>
            </ion-item>
          </ion-col>

          <ion-col size="11" style="margin: 15px;">
            <ion-item class="itemInput">
              <ion-label position="stacked">Contraseña</ion-label>
              <ion-input type="password" v-model="password"></ion-input>
            </ion-item>
          </ion-col>
          <ion-col size="11" style="margin: 15px;">
            <ion-button expand="block" @click="login">Iniciar Sesion</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>

    <!-- <ion-content>
    <ion-grid class="grid-login" style="text-align: center">
      <ion-row>
        <ion-col>
          <ion-card>

            <ion-card-header>
              <ion-card-title>Iniciar Sesion</ion-card-title>
            </ion-card-header>
            <br>

            <ion-card-content>
              <img :src="require('@/images/icon.png')">
              <br><br>
              <ion-grid>
                <ion-row>
                  <ion-col size="12">
                    <ion-item>
                      <ion-label position="floating">Usuario</ion-label>
                      <ion-input type="text" v-model="username"></ion-input>
                    </ion-item>
                  </ion-col>

                  <ion-col size="12">
                    <ion-item>
                      <ion-label position="floating">Contraseña</ion-label>
                      <ion-input type="password" v-model="password"></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </ion-grid>
              <br><br>
              <section>
                <ion-button expand="block" @click="login">Ingresar</ion-button>
              </section>
            </ion-card-content>
          </ion-card>
        </ion-col>

        <ion-col size="12">

        </ion-col>
      </ion-row>
    </ion-grid>

    </ion-content> -->
  </ion-page>
</template>

<script>
import UserService from '@/services/UserService';
import AuthService from '@/services/AuthService';
import { TokenUtils } from '@/utils/TokenUtils';
import * as AppUtils from '@/utils/AppUtils';
import {
  IonCol,
  IonGrid,
  IonRow,
  IonPage,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonLabel,
  IonItem,
  IonCardTitle,
  IonSelect,
  IonSelectOption,
  IonSegmentButton,
  IonSegment,
  toastController,
  IonInput,
  alertController,
  loadingController
} from '@ionic/vue';
import router from "../router";

export default {
  name: "login",
  components: {
    IonCol, IonGrid, IonRow, IonPage, IonInput, IonContent, IonLabel, IonItem
  },
  props: {
    source: String,
  },
  data: () => ({
    username: '',
    password: '',
  }),
  methods: {
    async authUser() {
      if (!this.username) {
        this.$swal('Error', 'Ingresa el usuario', 'error');
        return;
      }
      if (!this.password) {
        this.$swal('Error', 'Ingresa la contraseña', 'error');
        return;
      }

      const resp = await AuthService.auth(this.username, this.password);
      if (resp.error) {
        this.$swal('Error', `No se puede iniciar sesión, ${resp.error}`, 'error');
        return;
      }
      TokenUtils.setToken(resp.token);
      const payload = TokenUtils.getJwtData();

      const { identity: userId } = TokenUtils.getJwtData();
      const attributes = await UserService.getUserAttributes(userId);

      this.$store.dispatch('setAuthUserData', payload);
      this.$store.dispatch('setAttributes', attributes);

      AppUtils.redirectToUserSectionByAttributes(this.$router, attributes);
    },
    async login() {
      const loading = await loadingController.create({
        message: 'Please wait...',
        duration: 100000
      });
      await loading.present();
      /*setTimeout(async function () {
        setTimeout(function () {
          this.loading.dismiss()
        }, this.timeout);
        const alert = await alertController.create({
          header: 'Error',
          message: 'Error de conexion',
          buttons: ['Ok']
        });
        await alert.present();
      }, 100000);*/
      //router.push('/finish-registration');
      const resp = await AuthService.auth(this.username, this.password);
      console.log('resp', resp.error);
      // eslint-disable-next-line no-constant-condition
      if(resp.message !== undefined) {
        setTimeout(function() {
          loading.dismiss()
        }, this.timeout);
        const alert = await alertController.create({
          header: 'Error',
          message: resp.message,
          buttons: ['Ok']
        });
        await alert.present();
      } else {
        setTimeout(function() {
          loading.dismiss()
        }, this.timeout);
        TokenUtils.setToken(resp.token);
        const payload = TokenUtils.getJwtData();
        console.log('payload', payload);

        //router.push(`/finish-registration/?token=${resp.token}`);

        if(payload.register_completed == false) {
          router.push(`/finish-registration/?token=${resp.token}`);
        } else {
          router.push(`/menu/?token=${resp.token}`);
        }
      }
    },
  },
}
</script>

<style>

  .backgroundImage {
    --background: url('../../src/images/prueba2corta.png') no-repeat center center fixed;
  }

  .itemInput  {
    border-radius: 10px;
  }

</style>
